export const Menu = [{
        id: 0,
        title: "кальяны",
        image: "fuck.jpg",
        name: "hookah",
    },
    {
        id: 1,
        title: "кухня",
        image: "fuck.jpg",
        name: "kitchen",
    },
    {
        id: 2,
        title: "алкоголь",
        image: "fuck.jpg",
        name: "alcohol",
    },
    {
        id: 3,
        title: "напитки",
        image: "fuck.jpg",
        name: "drink",
    },
    {
        id: 4,
        title: "чай",
        image: "fuck.jpg",
        name: "tea",
    },
];

export const MenuAdmin = [{
    id: 0,
    title: "выйти",
    image: "fuck.jpg",
    name: "Logout",
}, ];

export const VoluemType = [{
        value: null,
        title: null
    },
    {
        value: 1,
        title: 'л'
    },
    {
        value: 2,
        title: 'мл'
    },
    {
        value: 3,
        title: 'кг'
    },
    {
        value: 4,
        title: 'г'
    },
]